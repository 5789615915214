export const firebaseConfig = {
  apiKey: 'AIzaSyBf9yE0hd1pzVc02xx-9h-ZYVcYP0C19rk',
  authDomain: 'auth.handle.com',
  databaseURL: 'https://handle-57379.firebaseio.com',
  projectId: 'handle-57379',
  storageBucket: 'handle-57379.appspot.com',
  messagingSenderId: '750623952809',
  appId: '1:750623952809:web:3ae1b4834e30d5c1c9beb8',
  measurementId: 'G-3V17FMC983',
};
